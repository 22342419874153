<template>
  <v-col cols="12" md="6" v-if="checkFAddress(value.codename)">
    <v-text-field
      v-if="value.Kind < 7 && value.Kind != 4"
      v-model="value.FieldValue"
      :type="inputOptions[value.Kind].type"
      :maxlength="inputOptions[value.Kind].type !== 'date' ? inputOptions[value.Kind].maxlength : ''"
      :max="inputOptions[value.Kind].type === 'date' ? inputOptions[value.Kind].maxlength : ''"
      :counter="inputOptions[value.Kind].type !== 'date' ? inputOptions[value.Kind].maxlength : false"
      :label="value.Kind != 11 ? value.Title : ''"
    ></v-text-field>
    <v-text-field
      v-else-if="value.Kind == 4"
      v-model="value.FieldValue"
      :label="value.Kind != 11 ? value.Title : ''"
      :placeholder="MaskPlaceHolder(value.Mask)"
      v-mask="changeMask(value.Mask)"
    ></v-text-field>
    <v-select
      v-else-if="value.Kind == 7"
      v-model="value.FieldValue"
      :items="CountryList"
      item-value="ID"
      item-text="Name"
      @change="setPassRegList(value)"
      :label="value.Title"
    ></v-select>
    <v-select
      v-else-if="value.Kind == 8"
      v-model="value.FieldValue"
      :items="TypeSettlement"
      item-value="ID"
      item-text="Name"
      :label="value.Title"
    ></v-select>
    <v-select
      v-else-if="value.Kind == 9"
      v-model="value.FieldValue"
      :items="value.codename == 'REGION' ? CountryRegionsList : CountryFRegionsList"
      item-value="ID"
      item-text="Name"
      :label="value.Title"
    ></v-select>
    <v-select
      v-else-if="value.Kind == 10"
      v-model="value.FieldValue"
      :items="StreetsTypesList"
      item-value="ID"
      item-text="Name"
      :label="value.Title"
    ></v-select>
    <v-checkbox v-else-if="value.Kind == 11" v-model="value.FieldValue" :label="value.Title"></v-checkbox>
  </v-col>
</template>

<script>
  import { mapGetters } from 'vuex'

  export default {
    name: 'dynoDocFields',
    props: ['value', 'hideFAddress'],
    data() {
      return {
        inputOptions: {
          0: {
            type: 'text',
            maxlength: 32,
          },
          1: {
            type: 'date',
            maxlength: '3000-12-31',
          },
          2: {
            type: 'number',
            maxlength: 32,
          },
          3: {
            type: 'text',
            maxlength: 256,
          },
          5: {
            type: 'text',
            maxlength: 32,
          },
          6: {
            type: 'date',
            maxlength: '3000-12-31',
          },
        },
      }
    },
    computed: {
      ...mapGetters({
        StreetsTypesList: 'StreetsTypesList',
        CountryList: 'CountryList',
        TypeSettlement: 'TypeSettlement',
        CountryRegionsList: 'CountryRegionsList',
        CountryFRegionsList: 'CountryFRegionsList',
      }),
    },
    methods: {
      setPassRegList(value) {
        console.log('change Regions')
        let data = {}
        data.id = value.FieldValue
        data.CodeName = value.codename
        this.$store.dispatch('loadCountryRegionsList', data)
      },
      checkFAddress(val) {
        if (val != null && val[0] == 'F' && val[1] != 'L') {
          return !this.hideFAddress
        }
        return true
      },
      changeMask(value) {
        function findAndReplace(string, target, replacement) {
          var i = 0,
            length = string.length
          for (i; i < length; i++) {
            string = string.replace(target, replacement)
          }
          return string
        }
        value = findAndReplace(value, ';0;_', '')
        value = findAndReplace(value, '0', '#')
        value = findAndReplace(value, 'A', '#')
        value = findAndReplace(value, 'L', 'A')
        value = findAndReplace(value, '\\', '')
        return value
      },
      MaskPlaceHolder(value) {
        function findAndReplace(string, target, replacement) {
          var i = 0,
            length = string.length
          for (i; i < length; i++) {
            string = string.replace(target, replacement)
          }
          return string
        }
        value = findAndReplace(value, ';0;_', '')
        value = findAndReplace(value, '0', '0')
        value = findAndReplace(value, 'A', '0')
        value = findAndReplace(value, '\\', '')
        return value
      },
    },
  }
</script>
