<template>
  <v-container>
    <v-card>
      <v-card-title class="headline d-flex font-weight-thin">
        Новая анкета <v-spacer />
        <v-btn @click="chekAndPush"><v-icon>mdi-content-save-outline</v-icon> сохранить</v-btn>
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="formValid" lazy-validation>
          <v-tabs v-model="tab" :grow="isMobile" centred>
            <v-tab v-for="(item, indx) in tabs" :key="item">
              <template v-if="isMobile">
                <v-icon class="mr-1">
                  {{ tabIcons[indx] }}
                </v-icon>
              </template>
              <template v-else>
                <v-icon>
                  {{ tabIcons[indx] }}
                </v-icon>
                {{ item }}
              </template>
              <v-icon v-if="!formValid && tabsValid[indx]()" color="error"> mdi-alert-circle </v-icon>
            </v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" class="mt-8" touchless>
            <v-tab-item key="Личные">
              <personal-data v-model="profile.personal" />
            </v-tab-item>
            <v-tab-item key="Паспорт">
              <passport-data v-model="profile.mainDoc" />
            </v-tab-item>
            <v-tab-item key="Доп.документы">
              <additionl-docs v-model="profile.ExtraDocs" />
            </v-tab-item>
          </v-tabs-items>
        </v-form>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script>
  import personalData from '../components/personalData.vue'
  import passportData from '../components/passportData.vue'
  import additionlDocs from '../components/additionlDocs.vue'

  export default {
    name: 'newAnket',
    components: {
      personalData,
      passportData,
      additionlDocs,
    },
    data() {
      return {
        profile: {
          personal: {
            photo_0: null,
            lastName: null,
            name: null,
            otherName: null,
            sex: 'M',
            birthdayD: null,
            birthdayM: null,
            birthdayY: null,
            dateFormatted: null,
            kindActivity: null,
            EmpSources: null,
            EmpSource_desc: null,
            interview: null,
            bankcard: null,
            contactFields: [
              {
                data: null,
                default: true,
                info: null,
                isPhone: 1,
                type: 1,
                title: 'Мобильный телефон',
              },
            ],
          },
          ExtraDocs: [],
          mainDoc: {
            CountryID: null,
            doctype: null,
            docTitle: null,
            fields: [],
            files: [],
          },
        },
        formValid: true,
        tab: 0,
        tabs: ['Личные', 'Паспорт', 'Доп.документы'],
        tabIcons: ['mdi-account-edit-outline', 'mdi-passport', 'mdi-briefcase-edit-outline'],
      }
    },
    created() {
      this.$store.dispatch('loadEmpContactsTypesList')
      this.$store.dispatch('loadCountry')
      this.$store.dispatch('loadEmpDocTypesList')
      this.$store.dispatch('loadStreetsTypesList')
      this.$store.dispatch('loadCountryList')
      this.$store.dispatch('loadTypeSettlement')
    },
    computed: {
      tabsValid() {
        return [
          () => {
            return !this.$refs.form.validate()
          },
          () => {
            if (this.profile.mainDoc.doctype) {
              return false
            } else {
              return true
            }
          },
          () => {
            if (this.profile.ExtraDocs.length === 0) {
              return false
            } else {
              let valid = false
              this.profile.ExtraDocs.forEach(doc => {
                doc.fields.forEach(val => {
                  if (!val.FieldValue) {
                    valid = true
                  }
                })
              })
              return valid
            }
          },
        ]
      },
      isMobile() {
        switch (this.$vuetify.breakpoint.name) {
          case 'xs':
          case 'sm':
            return true
          case 'md':
          case 'lg':
          case 'xl':
            return false
          default:
            return false
        }
      },
    },
    methods: {
      chekAndPush() {
        let valid = false
        this.tabsValid.forEach(val => {
          valid = val() || valid
        })
        if (this.formValid && !valid) {
          var formData = new FormData()
          formData.set('name', this.profile.personal.name)
          formData.set('lastName', this.profile.personal.lastName)
          formData.set('otherName', this.profile.personal.otherName)
          formData.set('sex', this.profile.personal.sex) //M W
          formData.set('birthdayD', this.profile.personal.birthdayD)
          formData.set('birthdayM', this.profile.personal.birthdayM)
          formData.set('birthdayY', this.profile.personal.birthdayY)

          formData.set('contactFields', JSON.stringify(this.profile.personal.contactFields))
          formData.set('kindActivity', this.profile.personal.kindActivity)
          formData.set('EmpSources', this.profile.personal.EmpSources)
          formData.set('EmpSource_desc', this.profile.personal.EmpSource_desc || '')
          formData.append('photo_0', this.profile.personal.photo_0)
          let fileset_name = []
          this.profile.ExtraDocs.forEach(e => {
            fileset_name = e.doctype
            e.files.forEach((v, i) => {
              formData.append('doc_' + fileset_name + '_add_' + i, v)
            })
          })
          this.profile.mainDoc.files.forEach((e, i) => {
            formData.append('doc_' + this.mainDoc[0].doctype + '_add_' + i, e)
          })
          this.profile.ExtraDocs.forEach(e => delete e.files)
          this.profile.mainDoc.files = []
          formData.set('mainDoc', JSON.stringify(this.profile.mainDoc.doctype ? this.profile.mainDoc : []))
          formData.set('ExtraDocs', JSON.stringify(this.profile.ExtraDocs))

          this.$store.dispatch('ProfileSend', formData)
        } else {
          this.$store.dispatch('setSnackBarInfoText', {
            color: 'info',
            show: true,
            text: 'Заполните остальные данные',
          })
        }
      },
    },
  }
</script>
