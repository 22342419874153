<template>
  <v-row>
    <v-col cols="12" md="4">
      <v-card class="pa-3 ma-1">
        <h2>Основная информация</h2>
        <v-img
          :src="imgToBase64(value.photo_0)"
          aspect-ratio="0.4"
          max-width="140"
          max-height="200"
          class="elevation-2 mt-4 mx-auto"
          contain
        ></v-img>
        <v-file-input
          v-model="uploadFile"
          accept="image/png, image/jpeg, image/bmp"
          placeholder="Pick an avatar"
          prepend-icon="mdi-camera"
          label="Фото"
          @change="setImg"
        ></v-file-input>
        <v-text-field
          v-model="value.lastName"
          label="Фамилия"
          outlined
          :rules="fieldRequired"
          :dense="dense"
          required
        ></v-text-field>
        <v-text-field
          v-model="value.name"
          label="Имя"
          outlined
          :dense="dense"
          :rules="fieldRequired"
          required
        ></v-text-field>
        <v-text-field
          v-model="value.otherName"
          label="Отчество"
          outlined
          :dense="dense"
          :rules="fieldRequired"
          required
        ></v-text-field>
        <p>Пол:</p>
        <v-radio-group v-model="value.sex" :column="false">
          <v-radio label="Мужской " value="M"></v-radio>
          <v-radio label="Женский" value="Ж"></v-radio>
        </v-radio-group>
        <v-menu
          ref="menu"
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          min-width="auto"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-model="dateFormatted"
              label="Дата рождения"
              prepend-icon="mdi-calendar"
              outlined
              readonly
              v-bind="attrs"
              :dense="dense"
              :rules="fieldRequired"
              @blur="date = parseDate(dateFormatted)"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="date"
            :active-picker.sync="activePicker"
            :max="new Date().toISOString().substr(0, 10)"
            min="1940-01-01"
            :first-day-of-week="1"
            locale="ru-ru"
            @change="save"
          ></v-date-picker>
        </v-menu>
      </v-card>
    </v-col>
    <v-col>
      <v-card class="pa-3 ma-1 mb-3">
        <h2>Контактная информация</h2>
        <contact-card v-model="value.contactFields" />
        <v-col class="d-flex">
          <v-select
            v-model="empContactsTypesSelect"
            :items="empContactsTypes"
            menu-props="auto"
            hide-details
            item-text="Title"
            label="Выберете тип дополнительной контактной информации"
            return-object
          >
            <template v-slot:prepend>
              <v-btn
                depressed
                class="ma-0 pt-0"
                :disabled="!empContactsTypesSelect"
                @click.stop="addContacts"
              >
                <v-icon>mdi-plus</v-icon>
              </v-btn>
            </template>
          </v-select>
        </v-col>
      </v-card>
      <v-card class="pa-3 ma-1">
        <v-autocomplete
          v-model="value.kindActivity"
          label="Вид деятельности"
          :items="professionsList"
          item-text="Name"
          item-value="ID"
          :rules="fieldRequired"
        ></v-autocomplete>
        <v-autocomplete
          v-model="value.EmpSources"
          label="Источник"
          :items="empSourcesList"
          item-text="Title"
          item-value="ID"
          :rules="fieldRequired"
        ></v-autocomplete>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import contactCard from './contactCard.vue'

  export default {
    name: 'personalData',
    props: ['value'],
    components: {
      contactCard,
    },
    data() {
      return {
        fieldRequired: [v => !!v || 'Необходимо заполнить'],
        uploadFile: null,
        maxContacts: 10,
        empContactsTypesSelect: null,
        EmpContactsTypes: [],
        activePicker: null,
        date: null,
        menu: false,
        valid: false,
        dateFormatted: null,
        empImg: null,
      }
    },
    computed: {
      ...mapGetters({
        empContactsTypes: 'empContactsTypes',
        empSourcesList: 'empSourcesList',
        professionsList: 'professionsList',
      }),
      dense() {
        return true
      },
    },
    created() {
      this.$store.dispatch('loadEmpContactsTypesList')
      this.$store.dispatch('loadProfessionsList')
      this.$store.dispatch('loadEmpSourcesList')
    },
    watch: {
      dateFormatted(newValue) {
        this.value.dateFormatted = newValue
        if (newValue) {
          // prettier-ignore
          [this.value.birthdayD, this.value.birthdayM, this.value.birthdayY] = newValue.split('/')
        } else {
          // prettier-ignore
          [this.value.birthdayD, this.value.birthdayM, this.value.birthdayY] = [null, null, null]
        }
      },
      menu(val) {
        val && setTimeout(() => (this.activePicker = 'YEAR'))
      },
      date() {
        this.dateFormatted = this.formatDate(this.date)
      },
    },
    methods: {
      setImg() {
        this.value.photo_0 = this.uploadFile
      },
      imgToBase64(file) {
        return file ? window.URL.createObjectURL(file) : ''
      },
      addContacts() {
        if (this.empContactsTypesSelect && this.contactFields.length < this.maxContacts) {
          this.contactFields.push({
            data: '',
            default: false,
            info: null,
            isPhone: this.empContactsTypesSelect.PhoneFlag,
            type: this.empContactsTypesSelect.ID,
            title: this.empContactsTypesSelect.Title,
          })
        } else {
          alert(
            'Максимальное количество записей ' +
              this.maxContacts +
              '. Если вам необходимо добавить больше обратитесь в службу поддержки.'
          )
        }
      },
      save(date) {
        this.$refs.menu.save(date)
        // this.$store.getters()
      },
      formatDate(date) {
        if (!date) return null
        const [year, month, day] = date.split('-')
        return `${day}/${month}/${year}`
      },
      parseDate(date) {
        if (!date) return null
        const [month, day, year] = date.split('/')
        return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
      },
    },
  }
</script>
