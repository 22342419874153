<template>
  <v-row>
    <v-col cols="12" md="6" class="d-flex">
      <v-btn depressed class="mt-1" @click.stop="addExtraDoc()">
        <v-icon>mdi-plus</v-icon>
      </v-btn>
      <v-select
        v-model="selectedDocType"
        placeholder="Выберете тип документа"
        item-text="title"
        item-value="id"
        return-object
        class="pl-2 pt-0"
        :items="ExtraDocTypeList"
      >
        <template v-slot:prepend> </template>
      </v-select>
    </v-col>
    <v-col cols="12" class="mb-3">
      <v-row>
        <v-card
          v-for="(item, index) in value"
          elevation="4"
          color="blue-grey lighten-5"
          max-width="460"
          min-width="220"
          height="100%"
          class="ma-3 pa-2"
          :key="index"
        >
          <v-card-title>
            {{ item.docTitle }}
          </v-card-title>
          <v-card-text>
            <template v-for="field in item.fields">
              {{ field.Title }}
              {{ field.FieldValue }}
              <v-text-field
                v-model="field.FieldValue"
                :placeholder="MaskPlaceHolder(field.Mask)"
                v-mask="changeMask(field.Mask)"
                :key="field.FieldID"
                :type="inputOptions[field.Kind].type"
                :maxlength="
                  inputOptions[field.Kind].type !== 'date' ? inputOptions[field.Kind].maxlength : ''
                "
                :max="inputOptions[field.Kind].type === 'date' ? inputOptions[field.Kind].maxlength : ''"
                :counter="
                  inputOptions[field.Kind].type !== 'date' ? inputOptions[field.Kind].maxlength : false
                "
                :rules="fieldRequired"
                solo
              ></v-text-field>
            </template>
            <template v-for="(file, i) in item.files">
              <v-row :key="'pic-' + i">
                <v-col>
                  <v-img max-width="100px" :src="imgToBase64(file.file)" />
                </v-col>
                <v-col> {{ i + 1 }}.{{ file.file.name }} </v-col>
                <v-col class="text-right">
                  <v-btn
                    small
                    class="ma-2"
                    text
                    icon
                    color="red lighten-2"
                    @click="removeFile(i, index, file.fileid)"
                  >
                    <v-icon>mdi-close-box</v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <v-file-input
              v-model="newFiles"
              color="deep-purple accent-4"
              label="Добавьте сканы документа"
              prepend-icon="mdi-paperclip"
              @change="handleDocFileUpload(index)"
              :display-size="1000"
              multiple
            >
            </v-file-input>
          </v-card-text>
          <v-card-actions>
            <v-spacer />
            <v-btn @click="delitem(index)" :key="item.doctype">Удалить</v-btn>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
  import Compress from 'compress.js'
  import { mapGetters } from 'vuex'

  export default {
    name: 'additionalDocs',
    props: ['value'],
    data() {
      return {
        newFiles: [],
        fieldRequired: [v => !!v || 'Необходимо заполнить'],
        valid: false,
        selectedDocType: null,
        inputOptions: {
          0: {
            type: 'text',
            maxlength: 32,
          },
          1: {
            type: 'date',
            maxlength: '3000-12-31',
          },
          2: {
            type: 'number',
            maxlength: 32,
          },
          3: {
            type: 'text',
            maxlength: 256,
          },
          4: {
            type: 'text',
            maxlength: 256,
          },
          5: {
            type: 'text',
            maxlength: 32,
          },
          6: {
            type: 'date',
            maxlength: '3000-12-31',
          },
        },
      }
    },
    computed: {
      ...mapGetters({
        ExtraDocTypeList: 'ExtraDocTypeList',
      }),
    },
    created() {
      this.$store.dispatch('loadCountry')
    },
    methods: {
      imgToBase64(file) {
        return window.URL.createObjectURL(file)
      },
      sizeInMB(value) {
        return Math.floor((value / 1024 / 1024) * 100) / 100
      },
      removeFile(lineId, value, fileid) {
        if (fileid) {
          this.fileToDelete.push({ fileid: fileid, doctype: this.extraDocs[value].doctype })
        }
        this.value[value].files.splice(lineId, 1)
      },
      async handleDocFileUpload(value) {
        console.log('val', value)
        console.log('files', this.newFiles)
        let extensionLists = {}
        const maxfiles = 10
        extensionLists.image = ['image/jpeg', 'image/png']
        function isValidFileType(fName, fType) {
          return extensionLists[fType].indexOf(fName.split('.').pop()) > -1
        }
        for (var i = 0; i < this.newFiles.length && i < maxfiles; i++) {
          if (
            this.value[value].files.find(e => {
              if (e.file.name == this.newFiles[i].name) {
                return true
              }
            })
          ) {
            alert('Этот файл уже добавлен')
          } else {
            if (this.newFiles[i].size > 16 * 1024 * 1024) {
              alert('Слишком большой файл')
            } else {
              if (isValidFileType(this.newFiles[i].type, 'image')) {
                const response = await this.convertImg(this.newFiles[i])
                this.value[value].files.push({ file: response })
              } else {
                alert('Недопустимый формат файла. Допустимые форматы (jpg,jpeg,png)')
              }
            }
          }
        }
        if (maxfiles < this.newFiles.length) {
          alert('Максимальное количество файлов:' + maxfiles)
        }
        this.$nextTick(() => (this.newFiles = []))
      },
      convertImg(fileToConvert) {
        return new Promise(resolve => {
          var newfileToConvert = []
          newfileToConvert.push(fileToConvert)
          var newfile = {}
          const compress = new Compress()
          compress
            .compress(newfileToConvert, {
              size: 0.5, // the max size in MB, defaults to 2MB
              quality: 0.95, // the quality of the image, max is 1,
              maxWidth: 1920, // the max width of the output image, defaults to 1920px
              maxHeight: 1920, // the max height of the output image, defaults to 1920px
              resize: true, // defaults to true, set false if you do not want to resize the image width and height
            })
            .then(results => {
              results.forEach(v => {
                function urltoFile(url, filename, mimeType) {
                  return fetch(url)
                    .then(function (res) {
                      return res.arrayBuffer()
                    })
                    .then(function (buf) {
                      return new File([buf], filename, { type: mimeType })
                    })
                }
                var filename = v.alt.split('.').slice(0, -1).join('.') + '.jpg'
                const base64str = v.data
                var ext = 'image/jpeg'
                var fullBase64 = 'data:image/jpeg;base64,' + base64str
                urltoFile(fullBase64, filename, ext).then(file => {
                  resolve((newfile.file = file))
                })
              })
            })
        })
      },
      async addExtraDoc() {
        if (this.checkSecondUseOfDocs(this.selectedDocType.id).length == 0) {
          if (this.selectedDocType) {
            let call = await this.filterExtraDocTypesList(this.selectedDocType.id)
            this.value.push({
              doctype: this.selectedDocType.id,
              docTitle: this.selectedDocType.title,
              fields: call,
              new: true,
              files: [],
            })
          }
        }
      },
      filterExtraDocTypesList(type) {
        return new Promise(resolve => {
          var sel = this.$store.getters.EmpDocTypesList.filter(item => {
            return item.DocTypeID == type
          })
          resolve(JSON.parse(JSON.stringify(sel)))
        })
      },
      checkSecondUseOfDocs(type) {
        return this.value.filter(function (item) {
          return item.doctype == type
        })
      },
      changeMask(value) {
        if (!value) return
        function findAndReplace(string, target, replacement) {
          var i = 0,
            length = string.length
          for (i; i < length; i++) {
            string = string.replace(target, replacement)
          }
          return string
        }
        value = findAndReplace(value, ';0;_', '')
        value = findAndReplace(value, '0', '#')
        value = findAndReplace(value, 'A', 'A')
        value = findAndReplace(value, 'a', 'A')
        value = findAndReplace(value, 'L', 'A')
        value = findAndReplace(value, '\\', '')
        return value
      },
      MaskPlaceHolder(value) {
        if (!value) return
        function findAndReplace(string, target, replacement) {
          var i = 0,
            length = string.length
          for (i; i < length; i++) {
            string = string.replace(target, replacement)
          }
          return string
        }
        value = findAndReplace(value, ';0;_', '')
        value = findAndReplace(value, '0', '0')
        value = findAndReplace(value, 'A', '')
        value = findAndReplace(value, 'a', '')
        value = findAndReplace(value, '\\', '')
        return value
      },
      delitem(index) {
        this.$nextTick(() => this.value.splice(index, 1))
      },
    },
  }
</script>
