<template>
  <v-row>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="value.CountryID"
        :items="citizenshipList"
        item-value="id"
        item-text="title"
        placeholder="Выберете страну"
        @change="value.doctype = null"
        :rules="selectRequired"
      ></v-autocomplete>
    </v-col>
    <v-col cols="12" md="6">
      <v-autocomplete
        v-model="value.doctype"
        :items="doctypes"
        item-text="title"
        item-value="docid"
        @change="setDocFields()"
        placeholder="Выберете тип документа"
        :disabled="doctypes.length === 0"
        :rules="selectRequired"
      >
      </v-autocomplete>
    </v-col>
    <span></span>
    <v-card class="pa-3 ma-4" v-if="value.fields.length > 0">
      <v-row>
        <template v-for="(field, index) in value.fields">
          <dyn-fields
            v-model="value.fields[index]"
            :key="field.FieldID"
            :hideFAddress="hideFAddress"
          ></dyn-fields>
        </template>
      </v-row>
      <v-checkbox
        v-model="hideFAddress"
        label="Адрес проживания совпадает с адресом регистрации"
      ></v-checkbox>
    </v-card>
  </v-row>
</template>

<script>
  import { mapGetters } from 'vuex'
  import dynFields from '../components/dynamicDocFields'

  export default {
    name: 'PassportData',
    props: ['value'],
    components: {
      dynFields,
    },
    data() {
      return {
        selectRequired: [v => !!v || 'Необходимо заполнить'],
        hideFAddress: true,
        citizenship: null,
        typeDoc: null,
      }
    },
    computed: {
      ...mapGetters({
        citizenshipList: 'citizenshipList',
        TypeDocList: 'TypeDocList',
        ExtraDocTypeList: 'ExtraDocTypeList',
        empContactsTypes: 'empContactsTypes',
        empDocTypesList: 'EmpDocTypesList',
      }),
      dense() {
        return true
      },
      doctypes() {
        return this.TypeDocList.filter(doc => doc.conid === this.value.CountryID) || []
      },
    },
    methods: {
      setDocFields() {
        console.log('this', this.value)
        if (this.value.doctype) {
          this.value.fields = JSON.parse(
            JSON.stringify(this.empDocTypesList.filter(field => field.DocTypeID === this.value.doctype))
          )
        } else {
          this.value.fields = []
        }
      },
      imgToBase64(file) {
        return file ? window.URL.createObjectURL(file) : ''
      },
    },
  }
</script>
